<template>
  <div class="inside-os">
    <div class="com-title">
      <span>开奖结果</span>
      <select v-model="currentType" @change="chufaList">
        <option v-for="g in gameList" :key="g.Cato" :value="g.Cato">{{g.Gamename}}</option>

      </select>
      <label v-if="frequency!='day'">日期</label>
      <input v-if="frequency!='day'" type="date" v-model="kjDate">
      <!-- <el-date-picker style="height:30px;width:120px" value-format="yyyy-MM-dd" v-model="kjDate" type="date" :clearable="false" placeholder="选择日期">
      </el-date-picker> -->
      <button v-if="frequency!='day'" class="button" @click="searchKj"> 查询 </button>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      kjDate: this.getBeforeDate(0),
      currentType: "",
      gameList: [],
      currentItem: {},
      frequency: "",
    };
  },

  components: {},
  created() {
    this.getGameList();
  },
  computed: {
    ...mapState(["userInfo", "currentGame"]),
  },
  watch: {
    currentType(val) {
      let item = this.gameList.find((item) => item.Cato == val);
      console.log(item.Gamename);
      this.frequency = item.frequency;
      this.$router.push({
        path: item.path,
        query: {
          frequency: item.frequency,
          roomeng: item.roomeng,
          gametype: item.Gametype,
          kjDate: this.splitDate(this.kjDate),
        },
      });

      this.$store.commit("resetCountAdd");
    },
  },
  mounted() {},

  methods: {
    // 触发getList事件
    chufaList() {},
    searchKj() {
      let time = this.splitDate(this.kjDate);

      this.$store.commit("SETKJDATE", time);
      this.$store.commit("SETSEARCHKJCOUNTJIA");
    },
    // 查询游戏列表
    getGameList() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        official: 0,
      };
      //  hk6 六合类
      //  11x5  11选5
      //  k3  快3
      //  klsf8 快乐十分8球
      //  klsf5 快乐十分5球
      //  ssc 时时彩
      //  pk10 pl10类
      //  k3dw 快3定位类
      this.$http.post("getgamelist", obj).then((res) => {
        if (res.Status) {
          let obj = JSON.parse(res.Msg);

          let gameArr = obj.GameInfoS;
          gameArr.forEach((item) => {
            switch (item.Gametype) {
              case "pk10":
                item.path = "/kjpk10";
                break;
              case 'wxc':
              item.path='/kjwxc'
              break
              case "hk6":
                item.path = "/kjlhc";
                break;
              case "pl3":
                item.path = "/kjpl3";
                break;
              case "k3":
                item.path = "/kjk3";
                break;
              case "11x5":
                item.path = "/kj11x5";
                break;
              case "ssc":
                item.path = item.clientshowtype == "ssc" ? "kjssc" : "/kjsx";
                break;
              case "20x8":
                item.path =
                  item.clientshowtype == "klsf8" ? "/kjklsf" : "/kjnc";
                break;
              case "pcdd":
                item.path = "/kjpcdd";
                break;
              case "fantan":
                item.path = "/kjfantan";
                break;
              case "80x20":
                item.path = "/kjkl8";
                break;
              case "bingo":
                item.path = "/kjbgc";
                break;
            }
          });
          this.gameList = gameArr;

          this.currentType = this.currentGame.Cato;
          // console.log(gameArr, "aaaaaaaa游戏列表");
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
  },
};
</script>
<style >
.button {
  margin-left: 20px;
}
.inside-os .com-title button {
  font-weight: 400;
  border: none;
}
.skin-blue .inside-os .button {
  background: #2161b3;
}
.skin-red .inside-os .button {
  background: #c2455c;
}
.skin-brown .inside-os .button {
  background: #dfb55e;
}
.inside-os .button {
  padding: 0 8px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

/* lhc */
.r-o-lhc .com-table tr td.sb_green {
  color: green;
}
.r-o-lhc .com-table tr td.ds_s,
.r-o-lhc .com-table tr td.dx_x,
.r-o-lhc .com-table tr td.sb_blue {
  color: #00f;
}
.r-o-lhc .com-table tr td.ds_d,
.r-o-lhc .com-table tr td.dx_d,
.r-o-lhc .com-table tr td.sb_red {
  color: red;
}
.r-o-lhc .com-table tr td .lhc-ball span {
  display: inline-block;
  padding-right: 5px;
  /* margin-right: 5px; */
}
.r-o-lhc .com-table tr td .lhc-ball {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* ----- */
.inside-os .com-table td.td-ball div,
.inside-os .com-table td.td-ball p,
.inside-os .com-table th.td-ball div,
.inside-os .com-table th.td-ball p {
  margin: 0 auto;
}

.inside-os .com-table td.td-ball div,
.inside-os .com-table td.td-ball p,
.inside-os .com-table th.td-ball div,
.inside-os .com-table th.td-ball p {
  margin: 0 auto;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
table > tr {
  vertical-align: middle;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.inside-os .com-table {
  width: 100%;
}
.inside-os .res-open-content {
  padding: 10px 0;
}
.inside-os .opcate em {
  padding: 0 5px;
  color: #999;
}
.inside-os .opcate span.selected {
  color: #f98d5c;
  font-weight: 700;
}
.inside-os .opcate span {
  cursor: pointer;
}
.inside-os .opcate {
  margin-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.inside-os .com-title input,
.inside-os .com-title select {
  margin: 0 10px;
  font-weight: 400;
}
.inside-os select {
  padding: 3px 0 3px 3px;
  border: 1px solid #ccc;
  color: #666;
  -webkit-box-shadow: 0 -1px 0 0 #ccc;
  box-shadow: 0 -1px 0 0 #ccc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.inside-os select {
  padding: 3px 0 3px 3px;
  border: 1px solid #ccc;
  color: #666;
  -webkit-box-shadow: 0 -1px 0 0 #ccc;
  box-shadow: 0 -1px 0 0 #ccc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>